import React from 'react'
import Navbar from '../../components/navbar'
import BlogMain from '../../../static/images/insurance.jpg'


export default function hrstrategy() {
  return (
    <div>
       <Navbar/>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover" src={BlogMain} alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        {/* <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div> */}
        <h2 class="mb-6 text-3xl lg:text-4xl text-indigo-800 font-bold font-heading">How HR Strategy is evolving in the space of Employee Health and Wellness</h2>
        <div class="flex items-center justify-center">
          {/* <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div> */}
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-500 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs text-center">Published On November 25, 2021 | Raunak Jha</p>
          </div>
        </div>
      </div>
      <div class="max-w-3xl mx-auto -mb-6 ">
        <p class="mb-6  text-md lg:text-lg text-gray-600">In the fast changing and fast paced global workplace, where maintaining competitive advantage is paramount to success, identifying ways of sustaining employee well-being is of increasing importance to a range of stakeholders, both within the context of work and beyond. Within the workplace, well-being is important not only for individual employees in terms of maintaining their own good health but also for managers and organizations as there is evidence to suggest that poor well-being at work can have adverse effects on performance and overall production. Employee wellness is fast emerging as one of the key components of a holistic approach that companies are exploring to not only manage the benefits-costs but also to improve company culture, employee engagement and productivity. Wellness or well-being is a combination of physical, mental and spiritual health and in many cases, companies are also expanding this circle to include, financial health of their employees.

</p> 
        <p class="mb-6  text-md lg:text-lg text-gray-600">The pandemic has made one thing clear about the future of employer-employee relationships; humans are much more than just resources. Recognising employees as human beings and showing genuine concern for their well-being is a central tenet in any HR strategy. HR is now focusing on employee well-being through the lens of their recruitment strategies, work models, management structures, benefit profiles, work technology and workplace culture. Wellness can play a role in, and feed off of, all of these things and severely impact the overall employee experience if not addressed and maintained. HR can act as a coordinator and communicator between the administration and the employees. They can communicate the wellness strategies and plans that the employers have, to the company staff. HR executives can also reach out to health professionals, fitness experts, and nutritionists who are an integral part of any wellness program. An HR professional can help the company in assessing the need of the hour as he/she can actually be the connection between the employer and the employees. And can assist the company in bringing in the desired results and ascertain the program’s progress and success.</p>
        <p class="mb-6 pb-10  text-md lg:text-lg text-gray-600">RJAC, the consultancy firm, offers services to help employers structure their employee benefit linked programs keeping defined health and wellness related goals from the start. This helps to ensure a goal-driven approach with the right checkpoints to measure success parameters of the strategies adopted in terms of employee medical health, holistic wellness, productivity and retention parameters to take data-driven relevant changes in time which increases the success rate of adopted approaches.

 

</p>
      
       </div>
    </div>
  </div>
</section>



    </div>
  )
}
